<template>
  <div>
    <div v-if="loading" class="spinner-area">
      <b-spinner variant="custom" label="Loading..." />
    </div>
    <validation-observer ref="formItems">
      <b-form v-if="!loading">
        <b-row>
          <b-col cols="12" sm="6" lg="4" class="mb-2">
            <b-form-group label="CPF *" label-for="cpf">
              <validation-provider
                #default="{ errors }"
                name="CPF"
                rules="required|cpf"
              >
                <b-overlay
                  :show="loadingCpf"
                  rounded="lg"
                  opacity="0.6"
                >
                  <Cleave
                    id="cpf"
                    class="form-control"
                    :options="mask.cpf"
                    v-model="formData.cpf"
                    @input="validaCPF"
                    :disabled="getMode === 'update' || formDisabled.cpf"
                  />
                </b-overlay>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" lg="4" class="mb-2">
            <b-form-group label="Nome Completo *" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >
                <b-overlay
                  :show="loadingCpf"
                  rounded="lg"
                  opacity="0.6"
                >
                  <b-form-input
                    id="name"
                    v-model="formData.nome"
                    autocomplete="off"
                    :disabled="formDisabled.nome"
                  />
                </b-overlay>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" lg="4" class="mb-2">
            <b-form-group label="E-mail *" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="E-mail"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="formData.email"
                  autocomplete="off"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" lg="4" class="mb-2">
            <b-form-group label="Perfis *" label-for="profiles" class="m-0">
              <validation-provider
                #default="{ errors }"
                name="Perfis"
                rules="required"
              >
                <v-select
                  id="profiles"
                  v-model="formData.perfil"
                  :options="getProfilesSelect"
                  variant="custom"
                  item-text="descricao"
                  item-value="id_perfil"
                  label="descricao"
                  placeholder="Selecione um perfil"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" lg="4" class="mb-2">
            <b-form-group
              label="Regionais SESI *"
              label-for="regions"
              class="m-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Regionais SESI"
                rules="required"
              >
                <v-select
                  id="regions"
                  v-model="formData.regioes"
                  :options="getRegionsSelect"
                  variant="custom"
                  v-if="formData.perfil && formData.perfil.descricao !== 'Admin Master' && formData.perfil.descricao !== 'Admin Modulo'"
                  item-text="descricao"
                  item-value="id_regiao"
                  label="descricao"
                  placeholder="Selecione uma regional"
                  multiple
                  multiselect
                />

                <b-form-input
                  value="Selecione uma regional"
                  readonly
                  v-else-if="!formData.perfil"
                />

                <b-form-input
                  value="Todas as regionais"
                  readonly
                  v-else
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" lg="4" class="mb-2">
            <b-form-group label="Módulos *" label-for="modules" class="m-0">
              <validation-provider
                #default="{ errors }"
                name="Módulos"
                rules="required"
              >
                <v-select
                  id="modules"
                  v-model="formData.modulos"
                  :options="getModulesSelect"
                  v-if="formData.perfil && formData.perfil.descricao !== 'Admin Master'"
                  variant="custom"
                  item-text="descricao"
                  item-value="id_modulo"
                  label="descricao"
                  placeholder="Selecione 1 ou mais"
                  multiple
                  multiselect
                />


                <b-form-input
                  value="Selecione um módulo"
                  readonly
                  v-else-if="!formData.modulos"
                />

                <b-form-input
                  value="Todos os módulos"
                  readonly
                  v-else
                />


                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          class="my-1"
        >
          <b-col cols="12" sm="6">
            <button
              type="button"
              class="btn btn-outline-cancel float-right"
              @click="$emit('cancel')"
            >
              Cancelar
            </button>
          </b-col>

          <b-col cols="12" sm="6">
            <button
              type="button"
              class="btn btn-insert float-left"
              @click="formSubmit"
            >
              Salvar
            </button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { email, cpf, required } from "@validations";
import vSelect from "vue-select";
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BSpinner,
  BOverlay
} from "bootstrap-vue";
import { modalGenericModel, modalGenericFillableErrorWithReload } from "@/libs/sweetalerts";
import Cleave from "vue-cleave-component";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BSpinner,
    BOverlay,
    vSelect,
    Cleave
  },

  props: {
    mode: {
      type: String,
      default: "insert",
    },
    chooseUser: {
      type: Object,
      default: null,
    },
    profilesSelect: {
      type: Array,
      default: null,
    },
    modulesSelect: {
      type: Array,
      default: null,
    },
    regionsSelect: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      email,
      cpf,
      required,

      loading: false,
      loadingCpf: false,

      formData: {
        id: "",
        nome: "",
        cpf: "",
        email: "",
        regioes: [],
        perfil: null,
        modulos: [],
      },
      mask: {
        cpf: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        }
      },
      formDisabled: {
        cpf: false,
        nome: false
      }
    };
  },

  computed: {
    getProfilesSelect() {
      return this.profilesSelect;
    },

    getRegionsSelect() {
      return this.regionsSelect;
    },

    getModulesSelect() {
      return this.modulesSelect;
    },

    getMode() {
      return this.mode;
    },

    getChooseUser() {
      return this.chooseUser;
    },
  },

  mounted() {
    if (this.getMode === "update") {
      this.getAdminUser();
    }
  },

  methods: {
    async validaCPF(cpf) {
      if(cpf.length === 11 && this.$props.mode !== 'update'){
        if (this.$helpers.validarCPF(cpf)){
          this.loadingCpf = true;
          this.formDisabled.cpf = true
          this.formDisabled.nome = true;
          await this.verificaCpfExiste(cpf);
          this.formDisabled.cpf = false
          this.loadingCpf = false;
        }
      }
    },

    async verificaCpfExiste(cpf) {
      let rotaCpf = this.$helpers.preparaRotaComParametros(
        this.$api.BuscaPessoaCpf,
        "{cpf}",
        cpf
      );
      await this.$http.get(rotaCpf)
      .then((res) => {
        if (res.status == 200) {
          this.formData.nome = res.data.nome ? res.data.nome : ''
          this.formDisabled.nome = true;
        } else{
          this.formData.nome = ''
          this.formDisabled.nome = false;
        }
      }).catch(() => {
        modalGenericFillableErrorWithReload(
          'Ocorreu um erro de comunicação com a base de dados. Tente novamente mais tarde.'
        )
      })
    },

    async formSubmit() {
      const result = new Promise((resolve, reject) => {
        this.$refs.formItems
          .validate()
          .then((success) => {
            if (success) {
              resolve(true);
            }
          })
          .catch(() => {
            reject();
          });
      });

      if (await result) {
        await this.core();
      }
    },

    async core() {
      if (this.getMode === "insert") {
        await this.handleInsert();
      }

      if (this.getMode === "update") {
        await this.handleUpdate();
      }
    },

    async handleInsert() {
      this.loading = true;

      const formData = this.setFormData();

      await this.$http
        .post(this.$api.UsuariosSesi, formData)
        .then((response) => {
          if (response.status === 200) {
            this.clearForm();
            modalGenericModel(
              'Sucesso!',
              'Dados salvos com sucesso.',
              require('@/assets/custom-icons/cora-icons/success.png'),
              'Ok'
            )
          }
        })
        .catch((error) => {
          this.handleError(error.response);
        });

      this.loading = false;
    },

    async handleUpdate() {
      this.loading = true;

      const formData = this.setFormData();

      await this.$http
        .put(this.$api.UsuariosSesiAtualizar(this.formData.id), formData)
        .then((res) => {
          if (res.status === 200) {
            this.clearForm();
            modalGenericModel(
              'Sucesso!',
              'Dados salvos com sucesso.',
              require('@/assets/custom-icons/cora-icons/success.png'),
              'Ok'
            )
          }
        })
        .catch((error) => {
          this.handleError(error.response);
        });

      this.loading = false;
    },

    async getAdminUser() {
      this.loading = true;

      await this.$http
        .get(`${this.$api.UsuariosSesi}/${this.getChooseUser.id}`)
        .then((response) => {
          if (response.status === 200) {
            const {
              id_usuario,
              nome,
              regiao,
              perfil_admin,
              modulo,
            } = response.data;
            this.formData = {
              id: id_usuario,
              nome,
              cpf: response.data.cpf,
              email: response.data.email,
              regioes: regiao,
              perfil: perfil_admin[0],
              modulos: modulo,
            };
          }
        })
        .catch((error) => {
          this.handleError(error.response);
        });

      this.loading = false;
    },

    setFormData() {
      let regioes = this.formData.regioes.map((item) => item.id_regiao);
      if (this.formData.perfil && (this.formData.perfil.descricao === 'Admin Master' || this.formData.perfil.descricao === 'Admin Modulo')) {
        regioes = ['all'];
      }

      let modulos = this.formData.modulos.map((item) => item.id_modulo);
      if(this.formData.perfil && this.formData.perfil.descricao === 'Admin Master'){
        modulos = this.getModulesSelect.map( (item) => item.id_modulo );
      }

      return {
        nome: this.formData.nome,
        email: this.formData.email,
        cpf: this.formData.cpf.replace(/\D/g, ""),
        regioes: regioes,
        perfis: [this.formData.perfil.id_perfil],
        modulos: modulos,
      };
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404;

      if (errors) {
        modalGenericModel(
          response.data.error.message,
          '',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Ok'
        )
        return;
      }
      modalGenericModel(
        'Não foi possível realizar a sua solicitação.<br> Entre em contato com o suporte.',
        '',
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Ok'
      )
    },

    clearForm() {
      this.formData = {
        nome: "",
        cpf: "",
        email: "",
        regioes: [],
        perfil: null,
        modulos: [],
      };

      this.$emit("success");
    },
  },
};
</script>

<style scoped>
.btn-insert {
  background: linear-gradient(45deg, #7030a0 0%, #a369ce 100%);
  color: #fff;
  transition: 0.3s background-color;
}

.btn-insert:hover {
  background: linear-gradient(45deg, #60248d 0%, #9950d0 100%);
  color: #fff;
}

.btn-outline-cancel {
  background-color: transparent;
  border: solid 0.094rem #82868b;
  color: #82868b;
  transition: 0.3s;
}

.btn-outline-cancel:hover {
  text-decoration: underline;
}

@media (max-width: 576px) {
  .btn-insert,
  .btn-outline-cancel {
    width: 100%;
    margin-bottom: 15px;
  }
}

div::v-deep .vs--disabled .vs__selected {
  background-color: #efefef !important;
  border: 1px solid #d8d6de;
  color: #5e5873;
}
</style>
