<template>
  <b-modal
    id="modal-player"
    ref="modal-player"
    size="lg"
    centered
    title="Player Vídeo"
    v-model="mostrarModal"
    @close="fecharModal"
    :hide-footer="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    >
        <h3 class="titulo">Insira o link do vídeo aqui (URL do YouTube)</h3>

        <b-form>
            <b-row>
                <b-col cols="10" class="mb-3 formulario">
                    <b-form-input type="text" placeholder="Link do vídeo" v-model="linkVideo"></b-form-input>
                </b-col>
            </b-row>
        </b-form>
        <div class="d-flex mb-3 justify-content-center videos" style="text-align: center">
            <iframe 
                width="560" height="315"
                :src="formattedUrl(linkVideo)"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen>
            </iframe>
        </div>
  </b-modal>
</template>

<script>
import {BForm, BRow,BCol,BFormGroup,BFormInput} from 'bootstrap-vue'
import { modalGenericModel } from '@/libs/sweetalerts'

export default {
    components: {
        BForm,
        BRow,
        BCol,
        BFormGroup,
        modalGenericModel,
        BFormInput
    },
    data() {
        return {
            mostrarModal: false,
            linkVideo: null
        }
    },
    props: {
        ativarModal: {
            type: Boolean,
            default: false
        },
        conteudo:{
            type: String,
            default: null,
            required: false
        }
    },
    mounted() {
        this.linkVideo = this.$props.conteudo
    },
    watch: {
        ativarModal(newValue) {
            this.mostrarModal = newValue
        },
    },
    methods: {
        fecharModal() {
            this.mostrarModal = false
            this.$emit('fecharModal')
        },
        formattedUrl(videoUrl) {
            try{
                if(!videoUrl){
                    return ''
                }

                // Verifica se o link está no formato correto (embed)
                if (videoUrl.includes('youtube.com/embed/')) {
                    return videoUrl;
                }

                // Caso seja um link curto ou normal, converte para o formato embed
                const url = new URL(videoUrl);
                let videoId = null;

                // Formato curto: https://youtu.be/{videoId}
                if (url.hostname === 'youtu.be') {
                    videoId = url.pathname.slice(1); // Remove a barra inicial
                }

                // Formato longo: https://www.youtube.com/watch?v={videoId}
                if (url.hostname.includes('youtube.com') && url.searchParams.has('v')) {
                    videoId = url.searchParams.get('v');
                }

                // Retorna o formato embed se o ID foi encontrado
                return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
            }catch(e){
                modalGenericModel(
                    'Formato de link inválido!',
                    'Por favor verifique o formato do link e tente novamente.',
                    require('@/assets/custom-icons/cora-icons/error.png'),
                    'Ok'
                )
            }
        },
    }
}
</script>

<style scoped>
.titulo{
    text-align: center;
    margin-bottom: 15px;
}
.formulario{
    margin: 0 auto;
}
iframe{
    border: none !important;
}
</style>
