<template>
  <div
    v-if="editor"
  >
    <div
      class="form-control border-bottom-correction"
    >
      <button
        class="button-style-extensions"
        type="button"
        @click="editor.chain().focus().toggleBold().run()"
        :disabled="!editor.can().chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        <feather-icon
          color="#82868B"
          icon="BoldIcon"
          size="20"
        />
      </button>
      <button
        class="button-style-extensions"
        type="button"
        @click="editor.chain().focus().toggleItalic().run()"
        :disabled="!editor.can().chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        <feather-icon
          color="#82868B"
          icon="ItalicIcon"
          size="20"
        />
      </button>
      <i class="bi bi-0-circle-fill"></i>
      <button
        class="button-style-extensions"
        type="button"
        @click="editor.chain().focus().toggleUnderline().run()"
        :disabled="!editor.can().chain().focus().toggleUnderline().run()"
        :class="{ 'is-active': editor.isActive('underline') }"
      >
        <feather-icon
          color="#82868B"
          icon="UnderlineIcon"
          size="20"
        />
      </button>
      <button
        class="button-style-extensions"
        type="button"
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#82868B" class="bi bi-list-ol" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5"/>
          <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635z"/>
        </svg>
      </button>
      <button
        class="button-style-extensions"
        type="button"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        <feather-icon
          color="#82868B"
          icon="ListIcon"
          size="20"
        />
      </button>
      <button
        class="button-style-extensions"
        type="button"
        @click="setLink"
        :class="{ 'is-active': editor.isActive('link') }"
      >
        <feather-icon
          color="#82868B"
          icon="LinkIcon"
          size="20"
        />
      </button>
      <button
        class="button-style-extensions"
        type="button"
        @click="abrirModalPlayer"
        :class="{ 'is-active': editor.isActive('play') }"
      >
        <feather-icon
          color="#82868B"
          icon="PlayIcon"
          size="20"
        />
      </button>
    </div>
    <div>
      <editor-content :editor="editor" />
    </div>
    <ModalPlay
      :ativarModal="abrirModalPlay"
      :conteudo="setLinkModal()"
      @fecharModal="abrirModalPlay = false"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import ModalPlay from '@/views/pages/ajuda-suporte/ModalPlayer.vue'

export default {
  components: {
    EditorContent,
    ModalPlay
  },

  props: {
    placeholder: {
      type: String,
      required: true
    },
    dadosContent: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      editor: null,
      abrirModalPlay: false
    }
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        Underline,
        Link.configure({
          openOnClick: false,
          defaultProtocol: 'https',
        }),
        StarterKit,
        Placeholder.configure({
          placeholder: this.$props.placeholder
        }),
      ],
      autofocus: false,
      content: this.$props.dadosContent,
      editorProps: {
        attributes: {
          class: 'form-control form-control-corretion-tip-tap',
        },
      }
    })
  },

  methods: {

    async setLink() {
      this.setBackgroundModalInert(true)
      await this.modalSetLink(
        this.setLinkModal()
      )
      this.setBackgroundModalInert(false)
    },

    async modalSetLink(
      inputValue
    ) {
      const link = this.editor
              .chain()
              .focus()
              .extendMarkRange('link')

      await this.$swal.fire({
        width: 550,
        imageUrl: require('@/assets/custom-icons/cora-icons/help.png'),
        imageWidth: 80,
        imageHeight: 80,
        input: "url",
        inputPlaceholder: 'Digite o link...',
        inputValue: inputValue,
        validationMessage: 'Link inválido, tente novamente.',
        title: 'Insira o link (URL) abaixo:',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary mr-2',
          input: 'mt-1 mb-1',
          validationMessage: 'mt-2'
        },
        didOpen: (modal) => {
          const clearButton = document.createElement('button');
          clearButton.type = 'button';
          clearButton.className = 'btn btn-outline-secondary mr-2';
          clearButton.innerText = 'Retirar Link';
          clearButton.addEventListener('click', () => {
            this.$swal.close();
          });
          const actions = modal.querySelector('.swal2-actions');
          actions.insertBefore(clearButton, actions.firstChild);
        },
        preConfirm: (value) => {
          inputValue = value
        }
      })
      .then((result) => {
        if(result.isConfirmed){
          if(inputValue !== ''){
            link.setLink({ href: inputValue })
                .run()
          }else{
            link.unsetLink()
                .run()
          }
        }else if(!result.dismiss){
          link.unsetLink()
              .run()
        }
      })
    },

    setLinkModal() {
      if(this.editor.getAttributes('link').href){
        return this.editor.getAttributes('link').href
      }else{
        return this.getLinksVideo()
      }
    },

    abrirModalPlayer() {
      this.abrirModalPlay = true
    },

    setBackgroundModalInert(status){
      const modalIds = [
        'modal-categoria',
        'modal-ajuda'
      ];

      modalIds.forEach((id) => {
        const foundModal = document.getElementById(id);
        if (foundModal) {
          foundModal.inert = status;
        }
      });
    },


    getLinksVideo() {
      const parser = new DOMParser();

      const doc = parser.parseFromString(this.dadosContent, 'text/html');

      const iframe = doc.querySelector('iframe')

      if(iframe){ 
        return iframe.src
      }

      return ''
    },

  },

  beforeUnmount() {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
.tiptap {
  :first-child {
    margin-top: 0;
    font-size: 1rem;
  }

  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  img {
    display: block;
    height: auto;
    margin: 1.5rem 0;
    max-width: 100%;

    &.ProseMirror-selectednode {
      outline: 3px solid var(--purple);
    }
  }

  a {
    color: var(--purple);
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: var(--purple-contrast);
    }
  }
}

.button-style-extensions{
  padding: 0;
  margin: 0;
  margin-right: 15px;
  background-color: white;
  border: none;
}

.border-bottom-correction{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}

.form-control-corretion-tip-tap{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 10px;
}

.ProseMirror {
  height: 250px;
  overflow: auto;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
</style>
