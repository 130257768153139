var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"spinner-area"},[_c('b-spinner',{attrs:{"variant":"custom","label":"Loading..."}})],1):_vm._e(),_c('validation-observer',{ref:"formItems"},[(!_vm.loading)?_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"CPF *","label-for":"cpf"}},[_c('validation-provider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-overlay',{attrs:{"show":_vm.loadingCpf,"rounded":"lg","opacity":"0.6"}},[_c('Cleave',{staticClass:"form-control",attrs:{"id":"cpf","options":_vm.mask.cpf,"disabled":_vm.getMode === 'update' || _vm.formDisabled.cpf},on:{"input":_vm.validaCPF},model:{value:(_vm.formData.cpf),callback:function ($$v) {_vm.$set(_vm.formData, "cpf", $$v)},expression:"formData.cpf"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3220685320)})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Nome Completo *","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-overlay',{attrs:{"show":_vm.loadingCpf,"rounded":"lg","opacity":"0.6"}},[_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","disabled":_vm.formDisabled.nome},model:{value:(_vm.formData.nome),callback:function ($$v) {_vm.$set(_vm.formData, "nome", $$v)},expression:"formData.nome"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2129215564)})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"E-mail *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","autocomplete":"off"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3001957823)})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('b-form-group',{staticClass:"m-0",attrs:{"label":"Perfis *","label-for":"profiles"}},[_c('validation-provider',{attrs:{"name":"Perfis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"profiles","options":_vm.getProfilesSelect,"variant":"custom","item-text":"descricao","item-value":"id_perfil","label":"descricao","placeholder":"Selecione um perfil"},model:{value:(_vm.formData.perfil),callback:function ($$v) {_vm.$set(_vm.formData, "perfil", $$v)},expression:"formData.perfil"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1963145623)})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('b-form-group',{staticClass:"m-0",attrs:{"label":"Regionais SESI *","label-for":"regions"}},[_c('validation-provider',{attrs:{"name":"Regionais SESI","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.formData.perfil && _vm.formData.perfil.descricao !== 'Admin Master' && _vm.formData.perfil.descricao !== 'Admin Modulo')?_c('v-select',{attrs:{"id":"regions","options":_vm.getRegionsSelect,"variant":"custom","item-text":"descricao","item-value":"id_regiao","label":"descricao","placeholder":"Selecione uma regional","multiple":"","multiselect":""},model:{value:(_vm.formData.regioes),callback:function ($$v) {_vm.$set(_vm.formData, "regioes", $$v)},expression:"formData.regioes"}}):(!_vm.formData.perfil)?_c('b-form-input',{attrs:{"value":"Selecione uma regional","readonly":""}}):_c('b-form-input',{attrs:{"value":"Todas as regionais","readonly":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2725928813)})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('b-form-group',{staticClass:"m-0",attrs:{"label":"Módulos *","label-for":"modules"}},[_c('validation-provider',{attrs:{"name":"Módulos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.formData.perfil && _vm.formData.perfil.descricao !== 'Admin Master')?_c('v-select',{attrs:{"id":"modules","options":_vm.getModulesSelect,"variant":"custom","item-text":"descricao","item-value":"id_modulo","label":"descricao","placeholder":"Selecione 1 ou mais","multiple":"","multiselect":""},model:{value:(_vm.formData.modulos),callback:function ($$v) {_vm.$set(_vm.formData, "modulos", $$v)},expression:"formData.modulos"}}):(!_vm.formData.modulos)?_c('b-form-input',{attrs:{"value":"Selecione um módulo","readonly":""}}):_c('b-form-input',{attrs:{"value":"Todos os módulos","readonly":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,909726336)})],1)],1)],1),_c('b-row',{staticClass:"my-1"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('button',{staticClass:"btn btn-outline-cancel float-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancelar ")])]),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('button',{staticClass:"btn btn-insert float-left",attrs:{"type":"button"},on:{"click":_vm.formSubmit}},[_vm._v(" Salvar ")])])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }