<template>
  <div v-if="objeto">
    <div class="d-flex align-items-center">
      <b-avatar rounded size="42" variant="custom" class="mr-1">
        <feather-icon :icon="icon" size="20" />
      </b-avatar>
      <div>
        <h4 class="mb-0">
          {{ objeto.titulo }}
        </h4>
        <span class="descricao-personalizada" v-html="objeto.descricao" />
      </div>
    </div>
    <app-collapse id="faq-payment-qna" accordion type="margin" class="mt-2">
      <div v-if="objeto.ajudas.length != 0">
        <app-collapse-item
          v-for="ajuda in objeto.ajudas"
          :key="ajuda.id_ajuda"
          :title="ajuda.topico"
        >
          <span class="descricao-personalizada" v-if="!ajuda.link" v-html="ajuda.conteudo"></span>
          <div class="descricao-personalizada" style="text-align: center">
            <iframe 
                v-if="ajuda.link"
                width="560" height="315"
                :src="ajuda.link"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen>
            </iframe>
          </div>
         
        </app-collapse-item>
       </div>
     <div v-else>Nenhum registro encontrado.</div>
    </app-collapse>
  </div>
</template>

<script>
import { BAvatar, BLink } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BAvatar,
    AppCollapseItem,
    AppCollapse,
    BLink,
  },
  mounted() {
    this.getLinks();
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    icon: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      objeto: null,
    };
  },

  methods: {
    getLinks() {
      if(this.options.id_categoria === 7){
        const parser = new DOMParser();
        this.options.ajudas.forEach(element => {
            
            const doc = parser.parseFromString(element.conteudo, 'text/html');
            const link = doc.querySelector('a')
            const iframe = doc.querySelector('iframe')

            if(link){
              element.link = this.formattedUrl(link.href)
            }
            if(iframe){
              element.link = iframe.src
            }
        });
        
      }
      this.objeto = this.options
    },

    formattedUrl(videoUrl) {
      if(!videoUrl){
          return ''
      }

      // Verifica se o link está no formato correto (embed)
      if (videoUrl.includes('youtube.com/embed/')) {
          return videoUrl;
      }

      // Caso seja um link curto ou normal, converte para o formato embed
      const url = new URL(videoUrl);
      let videoId = null;

      // Formato curto: https://youtu.be/{videoId}
      if (url.hostname === 'youtu.be') {
          videoId = url.pathname.slice(1); // Remove a barra inicial
      }

      // Formato longo: https://www.youtube.com/watch?v={videoId}
      if (url.hostname.includes('youtube.com') && url.searchParams.has('v')) {
          videoId = url.searchParams.get('v');
      }

      // Retorna o formato embed se o ID foi encontrado
      return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
            
    },
  }
};
</script>
<style lang="scss" scoped>
.descricao-personalizada::v-deep p {
  font-size: 1rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-weight: 400;
  line-height: 1.45;
  color: #5e5873;
}
iframe{
  border: none;
}
</style>
